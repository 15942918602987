import { Canvas } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Suspense } from 'react';
import { useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

function ModelViewer() {
  const modelPath = 'mac/scene.gltf';

  return (

    <Canvas shadows
    frameloop='demand'
    dpr={[1, 2]}
    gl={{ preserveDrawingBuffer: true }}
    camera={{
      fov: 45,
      near: 0.1,
      far: 200,
      position: [-4, 3, 6],
    }}>
     
     <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
      <OrbitControls
          autoRotate
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          rotation
        />
      
      
        <ModelLoader path={modelPath} />
      </Suspense>
    </Canvas>
    

  );
}

function ModelLoader({ path }) {
  const gltf = useLoader(GLTFLoader, path);

  return <primitive object={gltf.scene} scale={0.0005} />;
}

export default ModelViewer;