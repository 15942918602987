import React from 'react'
import './services.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import aldi from '../../assests/aldi.jpeg'
import mcd from '../../assests/mcd.png'

const Services = () => {
  return (
    <section id = 'services'>
      <h5>Jobs i have done</h5>
      <h2>My Work Experience</h2>
      <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' , borderRadius: 100/2}}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="Feb 2022 - present"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon = {
      <div>
      <img style={ {width: 60, height: 60, borderRadius: 400/ 2}} 
        src={aldi}

      />
    </div>
    }
    

  >
    <h3 className="vertical-timeline-element-title">Deputy Manager</h3>
    <h4 className="vertical-timeline-element-title">Aldi, Ballarat | Craigieburn</h4>
    <p className="vertical-timeline-element-title">
    •	Managing administrative work and safe handling </p>
<p>•	Experience in roster handling and Kronos</p>
<p>•	Handling store and customer complaints to provide best customer service</p>
<p>•	Leadership and team management with more than 20 members

    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderRadius: 100/2}}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="July 2020 - Jan 2022"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={
      <div className='icon-style-1'>
      <img style={ {width: 60, height: 60, borderRadius: 400/ 2}} 
        src={mcd}

      />
    </div>
    }>

    <h3 className="vertical-timeline-element-title">Crew Trainer</h3>
    <h4 className="vertical-timeline-element-subtitle">Mcdolands, Ballarat</h4>
    <p>
    •	Experienced in working as part of team, serving customers, and maintaining the store for the best experience</p>
<p>•	Managing rosters and staff</p>
<p> Awarded Employee of the Month in 2020 and 2021

    </p>
  </VerticalTimelineElement>
 
</VerticalTimeline>

    </section>
  )
}

export default Services
