import React from 'react'
import './about.css'
import ME from '../../assests/me.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id = 'about'>
      <h5> Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
<div className="about__me-image">
  <img src={ME} alt="My-image" />
</div>
        </div>

        <div className="about__content">
    <div className="about__cards">
      <article className='about__card'>
        <FaAward className='about__icons'/>
        <h5>Education</h5>
        <small>IT</small>

      </article>

      <article className='about__card'>
        <FiUsers className='about__icons'/>
        <h5>Clients</h5>
        <small>New User</small>

      </article>

      <article className='about__card'>
        <VscFolderLibrary className='about__icons'/>
        <h5>Projects</h5>
        <small>2 Project</small>

      </article>
    </div>
    <p>As a recent IT graduate, seeking to contribute my talent in coding languages, computer operation and development tools to create IT solutions and secure an IT internship. With strong teamwork, communication, and problem-solving skills, I hope to secure an entry level position. </p>

    <a href="contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About
