import React, { Suspense } from 'react'
import ModelViewer, { Model } from './mac'

import './testimonials.css'

const Testimonials = () => {
  return (
    <section id = 'testimonials'>
      <Suspense>
        <div className="mac">
     <ModelViewer/>
     </div>
     </Suspense>

    </section>
  )
}

export default Testimonials
