import React from 'react'
import ModelViewer from './Test'
import './contact.css'
import { Stars } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'

function Contact() {
  return (
    <section id ='contact'>
      <div className="contact-1">
      <ModelViewer/>
        <div className="star">
        
      <Canvas className='star'>
  
      <Stars/>
     
      </Canvas>
      </div>
      <div className="earth">
      
      </div>
      </div>
    </section>
  )
}

export default Contact

