import React from 'react'
import './portfolio.css'
import IMG1 from '../../assests/123.png'


const Portfolio = () => {
  return (
    <section id ='portfolio'>

    </section>
  )
}

export default Portfolio
