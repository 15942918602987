import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id ='experience'>
      <h5>The Skills I have</h5>
      <h2>My experience</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
<h3> Technical Skills</h3>
<div className="experience_content">

  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
   <div>
    <h4>HTML</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>


  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>CSS</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  

  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>React</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  
  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>JavaScript</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>


  
  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Java</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>


  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Firebase</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  
  
</div>
        </div>
        <div className="experience__backend">

        <h3> Soft Skills</h3>
<div className="experience_content">

  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Communication</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>


  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Leadership</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  

  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>React</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  


  
  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Teamwork</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>


  <article className='experience_details'>
    <BsPatchCheckFill className='experience__details-icons'/>
    <div> 
    <h4>Adminstrative</h4>
    <small className='text-light'>Experienced</small>
    </div> 
  </article>

  
  
</div>

        </div>
      </div>
    </section>
  )
}

export default Experience
