import ReactDOM from "react-dom";
import App from './App';
import './index.css'
import { HandLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
ReactDOM.render(<App/>, document.querySelector("#root"))


// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_6lCaEvGv9dYvyejE15HOGqCU_y1jPmQ",
  authDomain: "websiteres1.firebaseapp.com",
  projectId: "websiteres1",
  storageBucket: "websiteres1.appspot.com",
  messagingSenderId: "790632285097",
  appId: "1:790632285097:web:bae15267bd49009422bab8",
  measurementId: "G-D5CYBMMPMM"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);